import { ParsedResponse } from './util';
import {
  BackofficeKeyData,
  BackofficeKeyDataWithKey,
  BackofficeKeyEntityLevel,
  BackOfficeKeyQueryVariables,
  CreateBackOfficeKeyMutationVariables,
} from './API/types';
import { generateClient } from 'aws-amplify/api';
import { backOfficeKey } from './API/graphql/queries';
import {
  createBackOfficeKey,
  deleteBackOfficeKey,
} from './API/graphql/mutations';

export const list = async (
  merchantUid: string,
): Promise<ParsedResponse<Array<BackofficeKeyData | null>>> => {
  const client = generateClient();
  const variables: BackOfficeKeyQueryVariables = {
    merchant_uid: merchantUid,
    entity_level: BackofficeKeyEntityLevel.MERCHANT,
  };
  try {
    const response = await client.graphql({
      query: backOfficeKey,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.backOfficeKey!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch backoffice keys'] };
  }
};

export const createKey = async (
  merchant_uid: string,
  key_name: string,
): Promise<ParsedResponse<BackofficeKeyDataWithKey>> => {
  const client = generateClient();
  const variables: CreateBackOfficeKeyMutationVariables = {
    merchant_uid: merchant_uid,
    key_name: key_name,
    entity_level: BackofficeKeyEntityLevel.MERCHANT,
  };
  try {
    const response = await client.graphql({
      query: createBackOfficeKey,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.createBackOfficeKey!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to create backoffice key'] };
  }
};

export const deleteKey = async (
  merchant_uid: string,
  key_name: string,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables = {
    merchant_uid: merchant_uid,
    key_name: key_name,
  };
  try {
    const response = await client.graphql({
      query: deleteBackOfficeKey,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.deleteBackOfficeKey!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to delete backoffice key'] };
  }
};

export const updateKey = async (
  merchant_uid: string,
  key_name: string,
  enabled: boolean,
): Promise<ParsedResponse<boolean>> => {
  const variables = {
    merchant_uid: merchant_uid,
    key_name: key_name,
    enabled: enabled,
  };
  try {
    const response = await generateClient().graphql({
      query: deleteBackOfficeKey,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.deleteBackOfficeKey!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to delete backoffice key'] };
  }
};
